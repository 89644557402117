import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeStateType } from "../../redux/reducers/theme";
import { RootState } from "../../redux/store";

const ThemeProvider = ({ children }: any) => {
  const { darkMode } = useSelector<RootState>(
    (state) => state.theme
  ) as ThemeStateType;
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);
  return <>{children}</>;
};

export default ThemeProvider;
